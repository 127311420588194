body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
}

button {
    background-color: white;
    color: black;
    border: 2px solid #04AA6D;
    padding: 20px;
    transition-duration: 0.4s;
    display: block;
    margin: 0 auto;
    border-radius: 4px;
    font-size: 20px;
    text-align: center;
}
 
button:hover {
    background-color: #04AA6D;
    color: white;
}

button a {
    text-decoration: none;
    color: inherit;
    font-size: inherit;
}
  
input {
    font-size: 20px;
    border: 2px solid pink;
    border-radius: 4px;
    padding: 20px;
}

.center {
    font-size: 20px;
    text-align: center;
    padding-left: 16px;
}

/* alternative button style */
/*.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding: 10px;
    border: 5px solid #F47324;
} */

.center {
    max-width: 600px;
    margin: 0 auto;
}
  
form {
    display: flex;
    flex-direction: column;
}
  
.form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}
  
.form-group {
    flex: 1 1 calc(50% - 20px); /* Each input takes half the row with a gap */
    display: flex;
    flex-direction: column;
}
  
label {
   margin-bottom: 8px;
}

.container {
    padding: 0 100px;
}

/* Settings Table Styles */
.settings-table {
    margin: 20px 0;
}

.settings-table table {
    width: 100%;
    border-collapse: collapse;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    font-size: 14px;
}

.settings-table table th, 
.settings-table table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.settings-table table thead {
    background-color: #007BFF;
    color: white;
    font-weight: bold;
}

.settings-table table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.settings-table table tbody tr:nth-child(odd) {
    background-color: white;
}

.settings-table h3 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

.logs-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 18px;
    text-align: left;
  }
  
  .logs-table th, .logs-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .logs-table th {
    background-color: #f4f4f4;
    color: #333;
  }
  
  .logs-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .logs-table tr:hover {
    background-color: #f1f1f1;
  }
  
